/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Global Formatting
- Page Section Formatting
- Responsive Formatting
*/

/* ----------------------------- Global Formatting ----------------------------- */
.App {height: 100%; color: #fff; font-family: 'Segoe UI', "Open Sans", Verdana, Arial, Helvetica, sans-serif; text-decoration: none; font-weight: normal; border: 0px;}

.App-body {
  background-color: #2A3C53;
  background-image: linear-gradient(174deg, #212C4B, #1C395A, #0D6D8A, #6EA1AB, #93B5B3); /* Tim: Silubi UI gradient */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {color: #748892; font-family: 'Segoe UI', "Open Sans", Verdana, Arial, Helvetica, sans-serif; text-decoration: none; font-weight: 600; border: 0px;}
a:hover {color: #01a9ac; font-weight: 600;}

.App-link {color: #748892; display: none;}

img.silubi-logo {width: 200px; margin: 0px 0px 100px 0px;}

/* ----------------------------- Page Section Formatting ----------------------------- */

div#header {
    width: 100%;
    height: 40px;
    text-align: right;
    position: top;
    font-size: 14px;
    line-height: 4.5vh;
    border: 0px;
    margin: 0px;
    padding: 0px;}

div#content {width: 100%;
    height: calc(100vh - 80px);
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    margin: 0px;
    padding: 0px; }

div#footer {width: 100%;
    height: 40px;
    color: #adb7be;
    font-size: 13px;
    text-align: center;
    line-height: 4.5vh;
    letter-spacing: 0.5px;
    border: 0px;
    margin: 0px;
    padding: 0px;
    background-color: #1E212E;
    opacity: 0.98;}

span.wide-break {display: inline;}
span.narrow-break {display: none;}

/* ----------------------------- Mobile Formatting ----------------------------- */

@media (max-width: 1200px) {
			
}
@media (max-width: 1024px) {
			
}
@media (max-width: 768px) {
    div#content {height: calc(100vh - 96px);}
    div#footer {line-height: normal; padding: 8px 0px;}
    span.wide-break {display: none;}
    span.narrow-break {display: inline;}
}
@media (max-width: 480px) {
			
}
@media (max-width: 320px) {
			
}
